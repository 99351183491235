import ErrorPage from "containers/common/ErrorPage";

export default function Page404() {
  return (
    <ErrorPage
      text={
        <>
          요청하신 페이지가 존재하지 않아요.
          <br />
          페이지가 삭제되었거나 URL이 잘못 입력되었을 수 있어요.
        </>
      }
    />
  );
}
